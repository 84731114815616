import React, { useEffect, useLayoutEffect, useContext, useState } from "react"
import { useRouter } from "next/router"

import { Navbar, Footer, Loader } from "components/anti"
import { LoadingContext } from "context/loading-context"
// import { ErrorStateContext } from "context/error-msg-context"
import Toast from "./global/toast/toast"
import { parseCookies, setCookie } from "nookies"
import { SET_CURRENCY } from "lib/redux/types"
import { useDispatch } from "react-redux"
import axios from "axios"
import { isEmpty } from "lodash"

const Layout = ({
  children,
  showFooter = true,
  footerColor,
  walletAddress,
  showMenu = true,
  navColorProps,
  withoutLink,
  onlyMain,
}) => {
  const router = useRouter()
  const [loaderTheme, setLoaderTheme] = useState("dark")
  const [location, setLocation] = useState({})

  // const { errorMsg, setErrorMsg } = useContext(ErrorStateContext)

  const navigate = (to) => {
    router.push(to)
  }

  // Loader Context
  // allows loader only shown when directly visited via URL
  const { initialLoading } = useContext(LoadingContext)

  // Mobile viewport height workaround
  // prevent quirky behaviour on mobile when cover is set to 100vh
  const changeHeight = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }
  useEffect(() => {
    changeHeight()
    window.addEventListener('resize', changeHeight)
    return () => window.removeEventListener('resize', changeHeight)
  }, [])

  // Transition link
  // for smooth transition effect
  useLayoutEffect(() => {
    const wrapper = document.querySelector("main")
    wrapper.classList.add("animated", "fadeIn")
  }, [])
  useEffect(() => {
    const wrapper = document.querySelector("main")
    const anchor = document.querySelectorAll("a")
    anchor.forEach((item) => {
      const navigation = (e) => {
        const redirect = item.getAttribute("href")
        const currentLoc = window.location.pathname
        e.preventDefault()
        if (currentLoc !== redirect) {
          wrapper.classList.remove("fadeIn")
          wrapper.classList.add("fadeOut")
          setTimeout(() => {
            navigate(redirect)
          }, 250)
        }
      }
      if (item.getAttribute("href") && !item.getAttribute("target")) {
        item.addEventListener("click", navigation)
      }
    })
    return () => {
      anchor.forEach((item) => {
        const navigation = (e) => {
          const redirect = item.getAttribute("href")
          const currentLoc = window.location.pathname
          e.preventDefault()
          if (currentLoc !== redirect) {
            wrapper.classList.remove("fadeIn")
            wrapper.classList.add("fadeOut")
            setTimeout(() => {
              navigate(redirect)
            }, 250)
          }
        }
        if (item.getAttribute("href") && !item.getAttribute("target")) {
          item.removeEventListener("click", navigation)
        }
      })
    }
  }, [])

  useLayoutEffect(() => {
    const light = ["news"]
    for (const path of light) {
      if (window.location.pathname.includes(path)) {
        setLoaderTheme("light")
      }
    }
  }, [])

  /** currency */
  const dispatch = useDispatch()
  const cookies = parseCookies()
  const currency = cookies.currency
  useEffect(() => {
    getLocation()
  }, [])
  useEffect(() => {
    if (!isEmpty(location)) {
      if (!currency) {
        if (location === "ID") {
          setCookie(null, "currency", "IDR", {
            maxAge: 30 * 24 * 60 * 60,
            path: "/",
          })
          dispatch({
            type: SET_CURRENCY,
            payload: { label: "IDR", value: 0 },
          })
        } else {
          setCookie(null, "currency", "USD", {
            maxAge: 30 * 24 * 60 * 60,
            path: "/",
          })
          dispatch({
            type: SET_CURRENCY,
            payload: { label: "USD", value: 1 },
          })
        }
      } else {
        dispatch({
          type: SET_CURRENCY,
          payload: { label: currency, value: currency === "IDR" ? 0 : 1 },
        })
      }
    }
  }, [currency, location])
  /** currency */

  const getLocation = async () => {
    let location
    const locationCookies = cookies.location;
    if (!locationCookies) {
      try {
        location = await axios.get(
          `https://ipgeolocation.abstractapi.com/v1/?api_key=${process.env.NEXT_PUBLIC_ABSTRACT_API_KEY}`
        )
        setCookie(null, "location", location?.data?.country_code, {
          maxAge: 30 * 24 * 60 * 60,
          path: "/",
        })
      } catch (error) {
        location = ""
      }
    } else {
      location = locationCookies
    }
    setLocation(location?.data?.country_code || location)
  }

  return (
    <>
      {/* {errorMsg && (
        <>
          <div className="error-msg d-none d-md-block">
            {errorMsg}
            <i className="air ai-times" onClick={() => setErrorMsg(null)}></i>
          </div>
          <div className="container d-block d-md-none">
            <div className="error-msg">
              {errorMsg}
              <i className="air ai-times" onClick={() => setErrorMsg(null)}></i>
            </div>
          </div>
        </>
      )} */}
      <Toast />
      <Loader variant="image" theme={loaderTheme} effect="fadeOut" />
      {!onlyMain && (
        <Navbar
          walletAddress={walletAddress}
          showMenu={showMenu}
          navColorProps={navColorProps}
          withoutLink={withoutLink}
        />
      )}
      <main>
        {!initialLoading ? children : <div className="h-vh-100 w-vw-100" />}
        {!onlyMain && (
          <Footer showFooter={showFooter} footerColor={footerColor} />
        )}
      </main>
    </>
  )
}

export default Layout
